import { Order } from '@/features/orders/orders.type'
import { Pricing, Warehouse } from '@/features/settings/settings.type'

import { APIParams, getHeaders, request, Response } from '../config/api'

const AppAPIService = {
  getOrders({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders`,
      ...options,
    })
  },
  getOrderCount({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-count`,
      ...options,
    })
  },
  getOrder({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}`,
      ...options,
    })
  },
  getPublicOrder({ options, variables }: APIParams<{ packingLinkId: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/order-packing-links/${
        variables?.packingLinkId
      }`,
      ...options,
    })
  },
  createOrder({ options }: APIParams<{ order: Order }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders`,
      method: 'POST',
      ...options,
    })
  },
  updateOrder({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}`,
      method: 'PUT',
      ...options,
    })
  },
  updateOrderStatus({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/status`,
      method: 'PUT',
      ...options,
    })
  },
  createOrderProduct({ options }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-products`,
      method: 'POST',
      ...options,
    })
  },
  publicCreateOrderProduct({ options }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/order-products`,
      method: 'POST',
      ...options,
    })
  },
  updateOrderProduct({
    options,
    variables,
  }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-products/${variables?.orderProductId}`,
      method: 'PUT',
      ...options,
    })
  },
  createOrderProductNote({ options }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-notes`,
      method: 'POST',
      ...options,
    })
  },
  updateOrderProductNote({
    options,
    variables,
  }: APIParams<{ noteId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-notes/${variables?.noteId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteOrderProductNote({
    options,
    variables,
  }: APIParams<{ noteId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-notes/${variables?.noteId}`,
      method: 'DELETE',
      ...options,
    })
  },
  cusUpdateOrderProduct({
    options,
    variables,
  }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/cus/order-products/${variables?.orderProductId}`,
      method: 'PUT',
      ...options,
    })
  },
  cusUpdateOrderProductStatus({
    options,
    variables,
  }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/cus/order-products/${
        variables?.orderProductId
      }/status`,
      method: 'PUT',
      ...options,
    })
  },
  deleteOrderProduct({
    options,
    variables,
  }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-products/${variables?.orderProductId}`,
      method: 'DELETE',
      ...options,
    })
  },
  deleteOrder({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getWarehouses({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/warehouses`,
      ...options,
    })
  },
  createWarehouse({ options }: APIParams<{ warehouse: Warehouse }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/warehouses`,
      method: 'POST',
      ...options,
    })
  },
  updateWarehouse({ options, variables }: APIParams<{ warehouseId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/warehouses/${variables?.warehouseId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteWarehouse({ options, variables }: APIParams<{ warehouseId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/warehouses/${variables?.warehouseId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getSuppliers({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/suppliers`,
      ...options,
    })
  },
  createSupplier({ options }: APIParams<{ supplier: { name: string } }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/suppliers`,
      method: 'POST',
      ...options,
    })
  },
  updateSupplier({ options, variables }: APIParams<{ supplierId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/suppliers/${variables?.supplierId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteSupplier({ options, variables }: APIParams<{ supplierId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/suppliers/${variables?.supplierId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getProductUnits({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/units`,
      ...options,
    })
  },
  createProductUnit({ options }: APIParams<{ unit: { name: string } }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/units`,
      method: 'POST',
      ...options,
    })
  },
  updateProductUnit({ options, variables }: APIParams<{ unitId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/units/${variables?.unitId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteProductUnit({ options, variables }: APIParams<{ unitId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/units/${variables?.unitId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getProductUnitOptions({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/unit-options`,
      ...options,
    })
  },
  publicGetProductUnitOptions({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/unit-options`,
      ...options,
    })
  },
  translate({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/translations`,
      method: 'POST',
      ...options,
    })
  },
  publicTranslate({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/translations`,
      method: 'POST',
      ...options,
    })
  },
  getCompanies({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/delivery-companies`,
      ...options,
    })
  },
  createCompany({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/delivery-companies`,
      method: 'POST',
      ...options,
    })
  },
  updateCompany({
    options,
    variables,
  }: APIParams<{
    companyId: number
  }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/delivery-companies/${variables?.companyId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteCompany({ options, variables }: APIParams<{ companyId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/delivery-companies/${variables?.companyId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getProductTypes({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-types`,
      ...options,
    })
  },
  createProductType({ options }: APIParams<{ name: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-types`,
      method: 'POST',
      ...options,
    })
  },
  updateProductType({
    options,
    variables,
  }: APIParams<{ productTypeId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-types/${variables?.productTypeId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteProductType({
    options,
    variables,
  }: APIParams<{ productTypeId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/order-product-types/${variables?.productTypeId}`,
      method: 'DELETE',
      ...options,
    })
  },
  searchOrderProductHscodes({
    options,
    variables,
  }: APIParams<{ orderProductId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/cus/order-products/${
        variables?.orderProductId
      }/hscodes`,
      ...options,
    })
  },
  getHscodeInfo({ options, variables }: APIParams<{ hscode: string }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/hscodes/${variables?.hscode}`,
      ...options,
    })
  },
  getPricings({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/pricings`,
      ...options,
    })
  },
  createPricing({ options }: APIParams<{ pricing: Pricing }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/pricings`,
      method: 'POST',
      ...options,
    })
  },
  updatePricing({ options, variables }: APIParams<{ pricingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/pricings/${variables?.pricingId}`,
      method: 'PUT',
      ...options,
    })
  },
  deletePricing({ options, variables }: APIParams<{ pricingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/pricings/${variables?.pricingId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getPricingOptions({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/pricing-options`,
      ...options,
    })
  },
  getOrderPricings({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/aggregated-pricings`,
      ...options,
    })
  },
  createOrderPricing({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/pricings`,
      method: 'POST',
      ...options,
    })
  },
  updateOrderPricing({
    options,
    variables,
  }: APIParams<{ orderId: number; orderPricingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/pricings/${
        variables?.orderPricingId
      }`,
      method: 'PUT',
      ...options,
    })
  },
  deleteOrderPricing({
    options,
    variables,
  }: APIParams<{ orderId: number; orderPricingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/pricings/${
        variables?.orderPricingId
      }`,
      method: 'DELETE',
      ...options,
    })
  },
  createPackage({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/packages`,
      method: 'POST',
      ...options,
    })
  },
  publicCreatePackage({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/packages`,
      method: 'POST',
      ...options,
    })
  },
  updatePackage({ options, variables }: APIParams<{ packageId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/packages/${variables?.packageId}`,
      method: 'PUT',
      ...options,
    })
  },
  publicUpdatePackage({
    options,
    variables,
  }: APIParams<{ packageId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/packages/${variables?.packageId}`,
      method: 'PUT',
      ...options,
    })
  },
  deletePackage({ options, variables }: APIParams<{ packageId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/packages/${variables?.packageId}`,
      method: 'DELETE',
      ...options,
    })
  },
  publicDeletePackage({
    options,
    variables,
  }: APIParams<{ packageId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/packages/${variables?.packageId}`,
      method: 'DELETE',
      ...options,
    })
  },
  getPackages({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/packages`,
      ...options,
    })
  },
  publicGetPackages({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/packages`,
      ...options,
    })
  },
  getPackageTypes({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/package-types`,
      ...options,
    })
  },
  getPackageTypeOptions({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/package-type-options`,
      ...options,
    })
  },
  publicGetPackageTypeOptions({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/package-type-options`,
      ...options,
    })
  },
  createPackageTracking({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/package-trackings`,
      method: 'POST',
      ...options,
    })
  },
  publicCreatePackageTracking({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/package-trackings`,
      method: 'POST',
      ...options,
    })
  },
  getPackageTrackings({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/package-trackings`,
      ...options,
    })
  },
  publicGetPackageTrackings({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/package-trackings`,
      ...options,
    })
  },
  updatePackageTracking({
    options,
    variables,
  }: APIParams<{ trackingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/package-trackings/${variables?.trackingId}`,
      method: 'PUT',
      ...options,
    })
  },
  publicGetPackageTracking({
    options,
    variables,
  }: APIParams<{ trackingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/package-trackings/${
        variables?.trackingId
      }`,
      ...options,
    })
  },
  deletePackageTracking({
    options,
    variables,
  }: APIParams<{ trackingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/package-trackings/${variables?.trackingId}`,
      method: 'DELETE',
      ...options,
    })
  },
  publicDeletePackageTracking({
    options,
    variables,
  }: APIParams<{ trackingId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/public/package-trackings/${
        variables?.trackingId
      }`,
      method: 'DELETE',
      ...options,
    })
  },
  adminGetWorkspaces({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/admin/organizations`,
      ...options,
    })
  },
  adminCreateWorkspace({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/admin/organizations`,
      method: 'POST',
      ...options,
    })
  },
  getOrderPackingLinks({ options, variables }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/packing-links`,
      ...options,
    })
  },
  createOrderPackingLink({
    options,
    variables,
  }: APIParams<{ orderId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/packing-links`,
      method: 'POST',
      ...options,
    })
  },
  deleteOrderPackingLink({
    options,
    variables,
  }: APIParams<{ orderId: number; packingLinkId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/orders/${variables?.orderId}/packing-links/${
        variables?.packingLinkId
      }`,
      method: 'DELETE',
      ...options,
    })
  },
  createCustomField({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/custom-fields`,
      method: 'POST',
      ...options,
    })
  },
  getCustomFields({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/custom-fields`,
      ...options,
    })
  },
  updateCustomField({
    options,
    variables,
  }: APIParams<{ customFieldId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/custom-fields/${variables?.customFieldId}`,
      method: 'PUT',
      ...options,
    })
  },
  deleteCustomField({
    options,
    variables,
  }: APIParams<{ customFieldId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/custom-fields/${variables?.customFieldId}`,
      method: 'DELETE',
      ...options,
    })
  },
  uploadFiles({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/files`,
      method: 'POST',
      ...options,
    })
  },
  deleteFile({ options }: APIParams<{ fileId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/files`,
      method: 'DELETE',
      ...options,
    })
  },
  getActivityLogs({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/activity-logs`,
      ...options,
    })
  },
  getCustomers({ options }: APIParams): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/customers`,
      ...options,
    })
  },
  updateCustomer({ options, variables }: APIParams<{ customerId: number }>): Response {
    return request({
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/customers/${variables?.customerId}`,
      method: 'PUT',
      ...options,
    })
  },
  getDocumentSignedUrls({ options }: APIParams): Response {
    return request({
      method: 'POST',
      headers: getHeaders(),
      url: `${import.meta.env.VITE_API}/files/signed-urls`,
      ...options,
    })
  },
}

export default AppAPIService
