import { Form, FormItemProps } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import classNames from 'classnames'
import React, { useState } from 'react'

type Props = FormItemProps & {
  className?: string
  listName?: string | any[]
  hasValue?: boolean
  hasPlaceholder?: boolean
  uncontrolled?: boolean
}

const FormItem: React.FC<Props> = props => {
  const [focus, setFocus] = useState(false)
  const form = Form.useFormInstance()

  const {
    required,
    children,
    className,
    label,
    hasValue: hasValueProp,
    listName,
    hasPlaceholder = false,
    uncontrolled = false,
    ...rest
  } = props
  const name = !listName
    ? props.name || ''
    : [
        ...(Array.isArray(listName) ? listName : [listName]),
        ...(Array.isArray(props.name) ? props.name : [props.name]),
      ]
  const value = Form.useWatch(name as NamePath, form)
  const hasValue = Array.isArray(value) ? value.length > 0 : value != null

  // validate status of the controlled form item is handled by the Form instance
  const isInvalid = uncontrolled && !!form.getFieldError(name).length

  const labelAsPlaceholder = !(focus || hasValue || hasValueProp) && !hasPlaceholder
  const labelColor = focus ? 'text-black-pearl-600' : 'text-wedgewood-500'

  const isTextarea = !!(children as React.ReactElement<{ autoSize: boolean }>)?.props
    ?.autoSize

  return (
    <div
      className={classNames(className, 'relative group customized-form-item')}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}>
      <Form.Item
        className='m-0'
        {...rest}
        validateStatus={isInvalid ? 'error' : undefined}
        name={uncontrolled ? undefined : rest.name}
        style={
          {
            '--form-item-height': !label ? '32px' : '52px',
            '--input-padding-top': !label ? '6px' : '24px',
          } as any
        }>
        {children}
      </Form.Item>
      {uncontrolled && (
        <Form.Item
          name={rest.name}
          rules={rest.rules}
          className='[&_.ant-form-item-control-input]:hidden mb-0 [&.ant-form-item-has-error]:mb-8'
        />
      )}
      <label
        className={classNames(
          'z-[1] text-base md:text-sm font-medium absolute top-4 left-4 transition-all ease-in pointer-events-none',
          !labelAsPlaceholder && '!top-1.5 !text-xs',
          labelColor,
          required && 'post-asterisk',
          rest.hidden && 'hidden',
          isTextarea && 'bg-white',
        )}>
        {label}
      </label>
    </div>
  )
}

export default FormItem
