import { Button, Form, Input, Typography } from 'antd'
import { AxiosError } from 'axios'
import { CustomizedModal, CustomizedModalProps } from 'packages/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import FormItem from '@/components/FormItem'
import useHandleAPIError from '@/hooks/useHandleAPIError'
import AppAPIService from '@/services/AppAPI'
import IAMService from '@/services/IAM'
import { queryClient } from '@/services/reactQuery'
import { QueryKeys } from '@/services/types'
import { getInputRule, InputRuleKey } from '@/utilities/input'
import { PlusOutlined } from '@ant-design/icons'

import { Account } from '../../auth/auth.type'
import { EmailRegisterPayload } from '../../auth/pages/register.page'
import { LocationForm } from './LocationForm'
import MemberSelect from '@/components/MemberSelect'

type Props = CustomizedModalProps & {
  customer?: Account
  customerQueryKeys: (QueryKeys | { page: number; per_page: number; search: string })[]
  mode?: 'create' | 'edit'
}

export enum FormField {
  FullName = 'full_name',
  Phone = 'phone',
  Email = 'email',
  Locations = 'locations',
  CompanyName = 'company_name',
  CompanyTaxCode = 'company_tax_code',
  CompanyAddress = 'company_address',
  CompanyRepresentative = 'company_representative',
  SalesAccountId = 'sales_account_id',
}

const CustomerFormModal: React.FC<Props> = ({
  open,
  customer,
  customerQueryKeys,
  mode,
  onClose,
  ...props
}) => {
  const handleAPIError = useHandleAPIError()
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!customer) return
    form.setFieldsValue({
      [FormField.FullName]: customer.full_name,
      [FormField.Phone]: customer.phone,
      [FormField.Email]: customer.email,
      [FormField.Locations]: customer.data?.locations || [{}],
      [FormField.CompanyName]: customer.data?.company?.name,
      [FormField.CompanyTaxCode]: customer.data?.company?.tax_code,
      [FormField.CompanyAddress]: customer.data?.company?.address,
      [FormField.CompanyRepresentative]: customer.data?.company?.representative,
      [FormField.SalesAccountId]: customer?.sales_account?.id,
    })
  }, [customer, form])

  const createCustomerMutation = useMutation<void, AxiosError, Partial<Account>>(
    async payload => {
      const res = await IAMService.register({
        options: {
          data: {
            ...payload,
            email: payload.email || '', // Add email field
            password: 'temporary_password', // Add a temporary password
            invitation_code: '', // Add an empty invitation code
            organization_id: '', // Add organization_id if available
            solution_id: '', // Add solution_id if available
          },
        },
      })
      return res.data
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(customerQueryKeys)
        handleCloseModal()
      },
      onError(e) {
        handleAPIError(e)
      },
    },
  )

  const updateCustomerMutation = useMutation<void, AxiosError, Partial<Account>>(
    async payload => {
      const res = await AppAPIService.updateCustomer({
        variables: {
          customerId: customer?.id as number,
        },
        options: {
          data: payload,
        },
      })
      return res.data
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(customerQueryKeys)
        handleCloseModal()
      },
      onError(e) {
        handleAPIError(e)
      },
    },
  )

  const handleSubmitForm = values => {
    const payload = {
      full_name: values[FormField.FullName],
      phone: values[FormField.Phone],
      email: values[FormField.Email],
      sales_account_id: values[FormField.SalesAccountId],
      data: {
        locations: values[FormField.Locations],
        company: {
          name: values[FormField.CompanyName],
          tax_code: values[FormField.CompanyTaxCode],
          address: values[FormField.CompanyAddress],
          representative: values[FormField.CompanyRepresentative],
        },
        onboarded: true,
      },
    }

    if (mode === 'create') {
      createCustomerMutation.mutate(payload as EmailRegisterPayload)
    } else {
      if (isFormDirty) {
        updateCustomerMutation.mutate(payload)
      }
    }
  }

  const getTitle = () => {
    return mode === 'create' ? t('Tạo khách hàng mới') : t('Cập nhật khách hàng')
  }

  const handleCloseModal = () => {
    onClose?.()
    form.resetFields()
  }

  return (
    <CustomizedModal
      open={open}
      hideOk
      title={getTitle()}
      onClose={handleCloseModal}
      {...props}>
      <Form
        className='flex flex-col gap-4 transition'
        form={form}
        onFinish={handleSubmitForm}
        onFieldsChange={() => setIsFormDirty(true)}>
        <FormItem
          label={t('Họ và tên')}
          name={FormField.FullName}
          className='w-full'
          rules={[
            getInputRule(InputRuleKey.Required, 'họ và tên', t),
            getInputRule(InputRuleKey.Content, 'họ và tên', t),
          ]}>
          <Input />
        </FormItem>

        <FormItem
          label={t('Số điện thoại')}
          name={FormField.Phone}
          className='w-full'
          rules={[
            getInputRule(InputRuleKey.Required, 'số điện thoại', t),
            getInputRule(InputRuleKey.Phone, 'số điện thoại', t),
          ]}>
          <Input />
        </FormItem>

        <FormItem
          label={t('Email')}
          name={FormField.Email}
          className='w-full'
          rules={[getInputRule(InputRuleKey.Required, 'email', t)]}>
          <Input type='email' />
        </FormItem>

        <FormItem
          label={t('Nhân viên phụ trách')}
          name={FormField.SalesAccountId}
          className='w-full'>
          <MemberSelect />
        </FormItem>

        <Form.List name={FormField.Locations}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <LocationForm
                  key={field.key}
                  field={field}
                  index={index}
                  remove={remove}
                  fieldsLength={fields.length}
                />
              ))}
              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                {t('Thêm địa chỉ giao hàng')}
              </Button>
            </>
          )}
        </Form.List>

        <div className='flex flex-col gap-2'>
          <Typography.Text className='font-semibold'>
            {t('Thông tin công ty')}
          </Typography.Text>
          <FormItem
            label={t('Tên công ty')}
            name={FormField.CompanyName}
            className='w-full'>
            <Input />
          </FormItem>
          <FormItem
            label={t('Mã số thuế')}
            name={FormField.CompanyTaxCode}
            className='w-full'>
            <Input />
          </FormItem>
          <FormItem
            label={t('Địa chỉ công ty')}
            name={FormField.CompanyAddress}
            className='w-full'>
            <Input />
          </FormItem>
          <FormItem
            label={t('Người đại diện')}
            name={FormField.CompanyRepresentative}
            className='w-full'>
            <Input />
          </FormItem>
        </div>

        <div className='flex gap-4'>
          <Button onClick={handleCloseModal} className='w-full'>
            {t('Hủy bỏ')}
          </Button>
          <Button
            type='primary'
            className='w-full'
            htmlType='submit'
            loading={
              createCustomerMutation.isLoading || updateCustomerMutation.isLoading
            }>
            {t('Lưu')}
          </Button>
        </div>
      </Form>
    </CustomizedModal>
  )
}

export default CustomerFormModal
