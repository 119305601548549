import { Tooltip, Typography } from 'antd'
import classNames from 'classnames'
import { ComingSoonTag, Logout02 } from 'packages/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'

import { FeatureItem, FeatureKey, FeatureMappings } from '@/config/solution'
import { useUser } from '@/contexts/useUser'
import { useWorkspace } from '@/contexts/useWorkspace'
import { Feature } from '@/types/server/workspace'
import { LOGO_FULL } from '@/utilities/constanst'
import { UserType } from '@/utilities/enum'

type Props = {
  openMobileNavbar: boolean
}

const SIDE_BAR_COLLAPSED = 'isSidebarCollapsed'

const Sidebar: React.FC<Props> = ({ openMobileNavbar }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { workspace } = useWorkspace()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  useEffect(() => {
    if (user?.type === UserType.CUSTOMER) {
      setIsCollapsed(true)
    }
  }, [user])

  const fixedFeatureGroups = [
    {
      features: [
        {
          id: 'orders',
          enabled: !!workspace,
          restricted: false,
        },
      ],
    },
  ]
  const dynamicFeatureGroups = [
    {
      id: 'Inventory',
      name: 'Kho',
      features: [
        {
          id: 'packings',
          enabled: false,
          restricted: user?.type === UserType.CUSTOMER,
        },
        {
          id: 'vehicles',
          enabled: false,
          restricted: user?.type === UserType.CUSTOMER,
        },
        {
          id: 'delivery',
          enabled: false,
          restricted: user?.type === UserType.CUSTOMER,
        },
      ],
      restricted: user?.type === UserType.CUSTOMER,
    },
    {
      id: 'finance',
      name: 'Tài chính',
      features: [
        {
          id: 'revenue',
          enabled: false,
          restricted: user?.type === UserType.CUSTOMER,
        },
        {
          id: 'expenses',
          enabled: false,
          restricted: user?.type === UserType.CUSTOMER,
        },
        {
          id: 'commissions',
          enabled: false,
          restricted: user?.type === UserType.CUSTOMER,
        },
      ],
      restricted: user?.type === UserType.CUSTOMER,
    },
    {
      id: 'customer',
      name: 'Khách hàng',
      features: [
        {
          id: 'customers',
          enabled: user?.type !== UserType.CUSTOMER,
          restricted: user?.type === UserType.CUSTOMER,
        },
      ],
      restricted: user?.type === UserType.CUSTOMER,
    },
    {
      id: 'system',
      name: 'Hệ thống',
      features: [
        {
          id: 'settings',
          enabled: true,
          restricted: user?.type === UserType.CUSTOMER,
        },
      ],
      restricted: user?.type === UserType.CUSTOMER,
    },
    {
      id: 'admin',
      name: 'Admin',
      features: [
        {
          id: FeatureKey.Workspaces,
          enabled: true,
          restricted: user?.type !== UserType.SUPER_ADMIN,
        },
      ],
      restricted: user?.type === UserType.CUSTOMER,
    },
  ]

  const handleCollapseSidebar = () => {
    setIsCollapsed(prevState => {
      const newState = !prevState
      localStorage.setItem(SIDE_BAR_COLLAPSED, `${newState}`)
      return newState
    })
  }

  const renderNavItem = (item: Feature) => {
    if (item.restricted || !FeatureMappings[item.id]) return null

    const { key, label, icon, href, hasTeaser } = FeatureMappings[item.id] as FeatureItem
    const Icon = icon
    const disabled = !(item.enabled || hasTeaser)

    return (
      <NavLink
        key={key}
        to={href}
        activeClassName='sidebar-item-active'
        onClick={e => {
          if (disabled) e.preventDefault()
        }}
        className={classNames('no-underline', disabled && 'cursor-default')}>
        {isCollapsed ? (
          <Tooltip title={t(label)} placement='right'>
            <div className='w-10 h-10 flex justify-center items-center'>
              <Icon
                className='my-2 text-wedgewood-400'
                width={20}
                height={20}
                stroke='currentColor'
              />
            </div>
          </Tooltip>
        ) : (
          <div className='flex items-center justify-between px-3 py-2'>
            <div className='flex gap-3 items-center'>
              <Icon
                width={20}
                height={20}
                stroke='currentColor'
                className='text-wedgewood-400'
              />
              <Typography.Text>{t(label)}</Typography.Text>
            </div>

            {!item.enabled && (
              <div className='self-end'>
                <ComingSoonTag>{t('Sắp có')}</ComingSoonTag>
              </div>
            )}
          </div>
        )}
      </NavLink>
    )
  }

  useEffect(() => {
    const isSidebarCollapsed = localStorage.getItem(SIDE_BAR_COLLAPSED)
    if (isSidebarCollapsed === 'false') {
      return
    }
    setIsCollapsed(!!isSidebarCollapsed)
  }, [])

  return (
    <div
      className={classNames(
        'w-[260px] border-solid border-0 border-r border-wedgewood-200 overflow-auto overflow-x-hidden bg-wedgewood-50 scrollbar-hide',
        'xl:static fixed transition duration-300 transform xl:translate-x-0 -translate-x-full top-0 left-0 z-[99] h-full',
        {
          '!w-16': isCollapsed,
          '!translate-x-0 duration-300 h-full': openMobileNavbar,
        },
      )}>
      {/* Logo and Collapse button */}
      <div
        className={classNames(
          'pt-6 pb-2 items-center flex',
          isCollapsed ? 'px-4 justify-center' : 'px-6 justify-between',
        )}>
        <Link to='/'>
          {!isCollapsed && <img height={24} alt='logo' src={LOGO_FULL} />}
        </Link>

        <Tooltip title={!isCollapsed ? t('Collapse menu') : ''}>
          <div
            onClick={handleCollapseSidebar}
            className='text-wedgewood-400 hover:text-black-pearl-600 cursor-pointer w-8 h-8 flex items-center justify-center rounded-full hover:bg-white'>
            <Logout02
              width={16}
              height={16}
              stroke='currentColor'
              className={isCollapsed ? 'rotate-180' : ''}
            />
          </div>
        </Tooltip>
      </div>

      {/* Feature groups */}
      <div className='p-4 pb-2 flex flex-col gap-2'>
        <div className={isCollapsed ? 'flex flex-col items-center gap-1' : ''}>
          {fixedFeatureGroups.map(({ features }) => features.map(renderNavItem))}
        </div>
      </div>

      <div className='p-4 pt-0 flex flex-col gap-2'>
        {dynamicFeatureGroups
          ?.filter(group => group.id !== 'admin' || user?.type === UserType.SUPER_ADMIN)
          .filter(group => !group.restricted)
          .map(group => (
            <div
              key={group.id}
              className={isCollapsed ? 'flex flex-col items-center gap-1' : ''}>
              <div className={!group.name ? 'hidden' : ''}>
                {isCollapsed ? (
                  <Tooltip title={t(group.name)} placement='right'>
                    <div className='text-wedgewood-400 text-center h-[18px]'>&bull;</div>
                  </Tooltip>
                ) : (
                  <div className='text-sm text-wedgewood-500 mb-1'>{t(group.name)}</div>
                )}
              </div>
              {/* Features */}
              {group.features.map(renderNavItem)}
            </div>
          ))}
      </div>
    </div>
  )
}

export default Sidebar
