import { Select, Skeleton } from 'antd'
import { SelectProps } from 'antd/lib'
import { AxiosError } from 'axios'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Member } from '@/features/settings/settings.type'
import useHandleAPIError from '@/hooks/useHandleAPIError'
import IAMService from '@/services/IAM'
import { QueryKeys } from '@/services/types'
import { FastPagination } from '@/types'
import { filterOption } from '@/utilities/form'

const MemberSelect: React.FC<SelectProps> = props => {
  const { t } = useTranslation()
  const handleAPIError = useHandleAPIError()

  const queryKey = [
    QueryKeys.GetMembers,
    {
      page: 1,
      size: 100,
    },
  ]

  const membersQuery = useQuery<FastPagination<Member>, AxiosError>(
    queryKey,
    async ({ queryKey }) => {
      const res = await IAMService.getMemberOptions({
        options: {
          params: queryKey[1],
        },
      })
      return res.data
    },
    {
      onError: handleAPIError,
    },
  )

  if (membersQuery.isLoading) {
    return (
      <Skeleton.Input rootClassName='!w-100' className='!w-100' active size='small' />
    )
  }

  const memberOptions = membersQuery.data?.items.map(member => {
    const { email, full_name: name, id } = member.account
    const youTag = member.is_current_member ? `(${t('you')})` : ''

    const label = name ? `${name} ${youTag} (${email})` : `${youTag} ${email}`
    return {
      label,
      value: id,
    }
  })

  return (
    <Select
      className={
        !props.placeholder
          ? '[&_.ant-select-selection-search-input]:!mt-5 [&_.ant-select-selection-search-input]:!ml-1'
          : ''
      }
      options={memberOptions}
      {...props}
      showSearch
      filterOption={filterOption}
      notFoundContent={
        membersQuery.isLoading ? <Skeleton.Input active size='small' /> : null
      }
    />
  )
}

export default MemberSelect
