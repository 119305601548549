import React from 'react'
import { Form, Select, Input, Button, Typography } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { FormListFieldData } from 'antd/lib/form/FormList'

import FormItem from '@/components/FormItem'
import { getInputRule, InputRuleKey } from '@/utilities/input'
import { filterOption } from '@/utilities/form'
import cities from '@/utilities/cities.json'
import districts from '@/utilities/districts.json'
import wards from '@/utilities/wards.json'
import { FormField } from './CustomerFormModal'

// Add this enum at the top of the file
enum LocationFormField {
  City = 'city',
  District = 'district',
  Ward = 'ward',
  Address = 'address',
}

interface LocationFormProps {
  field: FormListFieldData
  index: number
  remove: (index: number | number[]) => void
  fieldsLength: number
  disabled: boolean
}

export const LocationForm: React.FC<LocationFormProps> = ({
  field,
  index,
  remove,
  fieldsLength,
  disabled,
}) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()

  const city = Form.useWatch(
    [FormField.Locations, field.name, LocationFormField.City],
    form,
  )
  const district = Form.useWatch(
    [FormField.Locations, field.name, LocationFormField.District],
    form,
  )

  return (
    <div key={field.key} className='flex flex-col gap-2'>
      <div className='flex justify-between items-center'>
        <Typography.Text className='font-semibold'>
          {t('Địa chỉ giao hàng')} {index + 1}
        </Typography.Text>
        {!disabled && fieldsLength > 1 && (
          <Button
            type='text'
            onClick={() => remove(field.name)}
            icon={<DeleteOutlined />}
          />
        )}
      </div>
      <div className='w-full flex gap-2 justify-between'>
        <FormItem
          label={t('Thành phố')}
          listName={FormField.Locations}
          name={[field.name, LocationFormField.City]}
          className='w-1/3'
          required
          rules={[getInputRule(InputRuleKey.Required, 'thành phố', t)]}>
          <Select
            className='[&_.ant-select-selection-search-input]:!mt-5 [&_.ant-select-selection-search-input]:!ml-1'
            options={cities.map(city => ({
              label: city.name,
              value: city.name,
            }))}
            showSearch
            filterOption={filterOption}
            disabled={disabled}
          />
        </FormItem>
        <FormItem
          label={t('Quận/Huyện')}
          listName={FormField.Locations}
          name={[field.name, LocationFormField.District]}
          className='w-1/3'
          required
          rules={[getInputRule(InputRuleKey.Required, 'quận/huyện', t)]}>
          <Select
            className='[&_.ant-select-selection-search-input]:!mt-5 [&_.ant-select-selection-search-input]:!ml-1'
            options={districts
              .filter(
                district =>
                  district.parent_code === cities.find(c => c.name === city)?.code,
              )
              .map(district => ({
                label: district.name,
                value: district.name,
              }))}
            showSearch
            filterOption={filterOption}
            disabled={disabled}
          />
        </FormItem>
        <FormItem
          label={t('Phường/Xã')}
          listName={FormField.Locations}
          name={[field.name, LocationFormField.Ward]}
          className='w-1/3'
          required
          rules={[getInputRule(InputRuleKey.Required, 'phường/xã', t)]}>
          <Select
            className='[&_.ant-select-selection-search-input]:!mt-5 [&_.ant-select-selection-search-input]:!ml-1'
            options={wards
              .filter(
                ward =>
                  ward.parent_code === districts.find(d => d.name === district)?.code,
              )
              .map(ward => ({
                label: ward.name,
                value: ward.name,
              }))}
            showSearch
            filterOption={filterOption}
            disabled={disabled}
          />
        </FormItem>
      </div>
      <FormItem
        label={t('Địa chỉ cụ thể')}
        listName={FormField.Locations}
        name={[field.name, LocationFormField.Address]}
        className='w-full'
        rules={[getInputRule(InputRuleKey.Required, 'địa chỉ', t)]}>
        <Input disabled={disabled} />
      </FormItem>
    </div>
  )
}
