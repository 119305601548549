import { Button, Tooltip, Space } from 'antd'
import MemberSelect from '@/components/MemberSelect'
import { ColumnType } from 'antd/es/table'
import { AxiosError } from 'axios'
import { CustomizedTable } from 'packages/ui'
import { useDebounce, usePagination } from 'packages/utilities'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import AppAPIService from '@/services/AppAPI'
import { QueryKeys } from '@/services/types'
import { FastPagination } from '@/types'
import { Account } from '../auth/auth.type'

import CustomerFormModal from './components/CustomerFormModal'

const CustomersPage: React.FC = () => {
  const { t } = useTranslation()
  const [modalType, setModalType] = useState<'create' | 'edit'>()
  const [selectedCustomer, setSelectedCustomer] = useState<Account>()
  const [selectedSalesAccount, setSelectedSalesAccount] = useState<string | undefined>()

  const { pageNumber, onPageChange, onShowSizeChange, limit } = usePagination(0, 10, 1)
  const [searchKey, setSearchKey] = useState('')
  const debouncedSearchKey = useDebounce(searchKey, 300)

  useEffect(() => {
    onPageChange(1)
  }, [debouncedSearchKey, selectedSalesAccount])

  const queryKeys = useMemo(
    () => [
      QueryKeys.GetCustomers,
      {
        page: pageNumber,
        per_page: limit,
        search: debouncedSearchKey,
        sales_account_id: selectedSalesAccount,
      },
    ],
    [pageNumber, limit, debouncedSearchKey, selectedSalesAccount],
  )

  const customersQuery = useQuery<FastPagination<Account>, AxiosError>(
    queryKeys,
    async ({ queryKey: [, params] }) => {
      const res = await AppAPIService.getCustomers({
        options: {
          params,
        },
      })
      return res.data
    },
    { enabled: true },
  )

  const handleOpenModal = (type: 'create' | 'edit', customer?: Account) => {
    setModalType(type)
    setSelectedCustomer(customer)
  }

  const handleCloseModal = () => {
    setModalType(undefined)
    setSelectedCustomer(undefined)
  }

  const changeSearchKey = (e: { target: HTMLInputElement }) => {
    setSearchKey(e.target.value)
  }

  const handleSalesAccountChange = (value: string) => {
    setSelectedSalesAccount(value)
  }

  const columns: ColumnType<Account>[] = [
    { title: 'ID', key: 'id', dataIndex: 'id' },
    {
      title: t('Tên khách hàng'),
      key: 'full_name',
      dataIndex: 'full_name',
    },
    {
      title: t('Email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('Số điện thoại'),
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: t('Tên công ty'),
      key: 'company_name',
      dataIndex: ['data', 'company', 'name'],
    },
    {
      title: t('Số đơn hàng'),
      key: 'order_count',
      dataIndex: ['order_count'],
      render: (orderCount: number) => orderCount || 0,
    },
    {
      title: t('Sales phụ trách'),
      key: 'sales_account',
      dataIndex: ['sales_account'],
      render: (salesAccount: Account) =>
        salesAccount ? (
          <Tooltip title={salesAccount?.email || ''}>
            <span className='font-semibold text-wedgewood-600'>
              {salesAccount.full_name}
            </span>
          </Tooltip>
        ) : (
          <span>{t('Chưa có')}</span>
        ),
    },
    {
      title: t('Hành động'),
      key: 'actions',
      render: (_, record) => (
        <Button type='link' onClick={() => handleOpenModal('edit', record)}>
          {t('Sửa')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <CustomizedTable
        loading={customersQuery.isLoading}
        columns={columns}
        title={t('Tất cả khách hàng')}
        totalUnit={t('khách hàng')}
        tableLayout='fixed'
        onSearchChange={changeSearchKey}
        searchPlaceHolder={t('Tìm kiếm khách hàng')}
        dataSource={customersQuery.data?.items}
        rowKey={record => record.id}
        extra={
          <Space>
            <MemberSelect
              placeholder={t('Lọc theo Sales')}
              style={{ width: 200 }}
              allowClear
              onChange={handleSalesAccountChange}
            />
            {/* Uncomment if you want to add the create customer button */}
            {/* <Button
              type='primary'
              className='py-2 mr-2'
              onClick={() => handleOpenModal('create')}>
              {t('Tạo khách hàng mới')}
            </Button> */}
          </Space>
        }
        pagination={{
          current: pageNumber + 1,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          pageSize: limit,
          total: customersQuery.data?.total,
          onChange: pageNumber => onPageChange(pageNumber - 1),
        }}
      />

      <CustomerFormModal
        customer={selectedCustomer}
        customerQueryKeys={queryKeys}
        open={!!modalType}
        mode={modalType}
        onClose={handleCloseModal}
      />
    </>
  )
}

export default CustomersPage
