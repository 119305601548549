/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* 
  This library extends change-case by @blakeembrey to support converting case
  with Vietnames extraneous characters 
  
  Reference: https://github.com/blakeembrey/change-case
 */

import {
  camelCase as _camelCase,
  capitalCase as _capitalCase,
  constantCase as _constantCase,
  dotCase as _dotCase,
  headerCase as _headerCase,
  noCase as _noCase,
  paramCase as _paramCase,
  pascalCase as _pascalCase,
  pathCase as _pathCase,
  sentenceCase as _sentenceCase,
  snakeCase as _snakeCase,
} from 'change-case'

// Read more about the options here:
// https://github.com/blakeembrey/change-case#split-example
const DEFAULT_OPTIONS = {
  // By default, the library will strip out non ASCII characters
  // Since our app support Vietnamese language, we want to override
  // the default stripRegexp option
  stripRegexp: /\p{Zs}+/giu,
  splitRegexp: /([a-z])([A-Z0-9])|(_)/g,
}

// testString
export const camelCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _camelCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// Test String
export const capitalCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _capitalCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// TEST_STRING
export const constantCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _constantCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// test.string
export const dotCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _dotCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// Test-String
export const headerCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _headerCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// test string
export const noCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _noCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// test-string
export const paramCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _paramCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// TestString
export const pascalCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _pascalCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// test/string
export const pathCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _pathCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// Test string
export const sentenceCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _sentenceCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// test_string
export const snakeCase = (
  string: string | null | undefined,
  options?: Record<string, unknown>,
): string => {
  if (string == null) return ''
  return _snakeCase(string, { ...DEFAULT_OPTIONS, ...options })
}

// Find the next character in the alphabetical order. Eg:
// A -> B
// AZ -> BA
// Z -> AA
export const findNextChar = (string: string, carry = 1, firstLevel = true): string => {
  // A -> Z in based 36 radix is 10 -> 35 after parseInt
  // Values of i is ranged from 11 -> 36
  const i = (parseInt(string, 36) + 1) % 36

  // If i = 0 -> current char = 'Z'. We reset the factor to 1 to return 'A'
  const factor = i === 0 ? 1 : 0

  const hasLeftChar = string.slice(0, -1)
  const lastChar = string.charAt(string.length - 1)

  // "A" start from 10 -> multiply factor by 10
  const newLastChar = (factor * 10 + i).toString(36).toUpperCase()
  const prefix = (firstLevel || string.length === 1) && i !== 0 ? '' : 'A'

  if (carry) {
    return hasLeftChar
      ? findNextChar(hasLeftChar, i === 0 ? 1 : 0, false) + newLastChar
      : prefix + newLastChar
  }

  return hasLeftChar ? findNextChar(hasLeftChar, 0, false) + lastChar : lastChar
}

// Input:  1234567891011
// Output: 12345...91011
export const truncate = (
  input: string | number,
  length = 10,
  from: 'left' | 'middle' = 'left',
): string => {
  if (input === null || input === undefined) return ''
  const stringInput = input.toString()
  if (stringInput.length <= length) return stringInput
  switch (from) {
    case 'left':
      return `${stringInput.slice(0, length)}...`

    case 'middle':
      return `${stringInput.slice(0, Math.floor(length / 2))}...${stringInput.slice(
        stringInput.length - Math.ceil(length / 2),
      )}`
  }
}

// Add slash between non-falsy keys
// ["body"] => "body"
// ["body","email"] => "body / email"
export const concatString = (values: string[], seperator = '/'): string => {
  return values.reduce((acc, cur, index) => {
    if (values[index - 1]) {
      if (!cur) return acc
      return `${acc} ${seperator} ${cur}`
    }
    return acc + cur
  }, '')
}

// Must be between 10-15 characters, including numbers of 0-9, space and the '+' character
export const isValidPhoneNumber = (phone: string) => !!phone?.match(/^[\d +]{10,15}$/)

export const truncateString = (string = '', maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string

export const findVowel = function (letter) {
  const vowels = ['a', 'e', 'i', 'o', 'u']

  return vowels.includes(letter)
}

export const viNormalized = str => {
  str = str.toLowerCase()
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
  return str
}

export const isViMatched = (str, keyword) => {
  str = viNormalized(str)
  keyword = viNormalized(keyword)
  return str.includes(keyword)
}

export const isValidUrl = string => {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}
