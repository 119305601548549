import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { appRoutes } from '@/config/appRoutes'
import CustomersPage from '@/features/customers/customers.page'

const Customers: React.FC = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.customers} component={CustomersPage} />
    </Switch>
  )
}

export default Customers
